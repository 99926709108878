.App {
  text-align: center;
}

#root {
  height: 100%;
}

html {
  background-color: #f5f5f5;
}

.ant-menu-item {
  height: 50px !important;
  line-height: 50px !important;
}

.ant-select.readOnly,
.ant-picker.readOnly {
  pointer-events: none;
}

.ant-timeline-item {
  padding-bottom: 1px !important;
}

th.th-center {
  text-align: center !important;
}

.ant-row .ant-tag {
  margin: 0 10px !important;
}

.tdt-bottom.tdt-left {
  display: none !important;
}

/* .ant-modal-body {
  max-height: 60vh !important;
  overflow-y: auto;
  padding-left: 10px;
} */